import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "location"
};
const _hoisted_3 = {
  key: 0,
  class: "restaurant-list"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
  class: "store-header"
};
const _hoisted_6 = {
  class: "store-name"
};
const _hoisted_7 = {
  class: "store-distance"
};
const _hoisted_8 = {
  class: "store-address"
};
const _hoisted_9 = {
  class: "store-info"
};
const _hoisted_10 = {
  class: "store-status"
};
const _hoisted_11 = {
  key: 1,
  class: "empty-state"
};
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useMainStore } from '@/stores/mainStore';
import { useLocationStore } from '@/stores/location';
import { useStoreStore } from '@/stores/store';
import { useOrderStore } from '@/stores/orderStore';
import StepIndicator from '@/components/StepIndicator';
import TopNav from '@/components/TopNav';
import StoreDialog from '@/components/StoreDialog';
import { ElIcon, ElInput, ElTag, ElLoading } from 'element-plus';
import { Location, Document, Search } from '@element-plus/icons-vue';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const mainStore = useMainStore();
    const locationStore = useLocationStore();
    const storeStore = useStoreStore();
    const orderStore = useOrderStore();
    const searchQuery = ref('');
    const loadingInstance = ref(null);
    const storeDialogVisible = ref(false);
    const selectedStore = ref({});
    const nextStep = () => {
      orderStore.setRestaurant(selectedStore.value);
      router.push('/meals');
    };
    const goToCitySelector = () => {
      router.push('/cities');
    };
    onMounted(async () => {
      if (!locationStore.selectedCity) {
        showLoading();
        await locationStore.fetchLocation();
        hideLoading();
      }
    });
    const showLoading = () => {
      loadingInstance.value = ElLoading.service({
        lock: true,
        text: '加载中...',
        target: document.querySelector('.content'),
        background: 'rgba(255, 255, 255, 0.7)'
      });
    };
    const hideLoading = () => {
      if (loadingInstance.value) {
        loadingInstance.value.close();
        loadingInstance.value = null;
      }
    };
    const city = computed(() => locationStore.city);
    const selectedCity = computed(() => locationStore.selectedCity);
    const stores = computed(() => storeStore.stores);
    const filteredStores = computed(() => {
      if (!searchQuery.value) {
        return stores.value.filter(store => store.businessTimeGroupId !== 0);
      }
      return stores.value.filter(store => (store.storeName.includes(searchQuery.value) || store.address.includes(searchQuery.value)) && store.businessTimeGroupId !== 0);
    });
    const storeStatus = computed(() => {
      return store => {
        const storeHours = getStoreHours(store);
        const isOpen = checkIfOpen(storeHours);
        return store.wxTakeoutReceiveOrder === 1 && isOpen ? {
          type: 'success',
          text: '营业中'
        } : {
          type: 'info',
          text: '休息中'
        };
      };
    });
    const getStoreHours = store => {
      const currentDay = new Date().getDay(); // 获取当前星期几，0 表示周日，1 表示周一，依此类推
      const groupDetails = store.businessTimeGroup.groupDetails;
      const todayDetails = groupDetails.find(detail => detail.dayOfWeek === currentDay);
      if (todayDetails && todayDetails.timePeriod.length > 0) {
        const timePeriod = todayDetails.timePeriod[0];
        return `${timePeriod.start}-${timePeriod.end}`;
      }
      return '未提供营业时间';
    };
    const checkIfOpen = storeHours => {
      if (storeHours === '未提供营业时间') {
        return false;
      }
      const [start, end] = storeHours.split('-');
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const isWithinBusinessHours = (currentHour > startHour || currentHour === startHour && currentMinute >= startMinute) && (currentHour < endHour || currentHour === endHour && currentMinute <= endMinute);
      return isWithinBusinessHours;
    };
    const onSearchInput = async () => {
      showLoading();
      await storeStore.fetchStores('', searchQuery.value, locationStore.longitude, locationStore.latitude);
      hideLoading();
    };
    const selectStore = store => {
      selectedStore.value = store;
      storeDialogVisible.value = true;
    };
    const confirmStore = () => {
      storeDialogVisible.value = false;
      nextStep();
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(TopNav), {
        title: "选择汉堡王门店"
      }), _createVNode(_unref(StepIndicator), {
        activeStep: 0
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(ElIcon), null, {
        default: _withCtx(() => [_createVNode(_unref(Location))]),
        _: 1
      }), _createElementVNode("span", {
        onClick: goToCitySelector
      }, _toDisplayString(selectedCity.value ? selectedCity.value.name : city.value), 1), _createVNode(_unref(ElInput), {
        placeholder: "门店搜索",
        modelValue: searchQuery.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchQuery.value = $event),
        class: "search-input",
        clearable: ""
      }, {
        append: _withCtx(() => [_createVNode(_component_el_button, {
          onClick: onSearchInput,
          icon: _unref(Search)
        }, null, 8, ["icon"])]),
        _: 1
      }, 8, ["modelValue"])]), filteredStores.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredStores.value, store => {
        return _openBlock(), _createElementBlock("div", {
          key: store.id,
          class: "restaurant-item",
          onClick: $event => selectStore(store)
        }, [_createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(store.storeName), 1), _createElementVNode("div", _hoisted_7, "距离 " + _toDisplayString((store.distance / 1000).toFixed(1)) + "km", 1)]), _createElementVNode("div", _hoisted_8, _toDisplayString(store.address), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", null, "营业时间: " + _toDisplayString(getStoreHours(store)), 1), _createElementVNode("div", null, "联系电话: " + _toDisplayString(store.telephone), 1)]), _createElementVNode("div", _hoisted_10, [_createVNode(_unref(ElTag), {
          type: storeStatus.value(store).type
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(storeStatus.value(store).text), 1)]),
          _: 2
        }, 1032, ["type"])])], 8, _hoisted_4);
      }), 128))])) : _createCommentVNode("", true), !loadingInstance.value && !filteredStores.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_unref(ElIcon), null, {
        default: _withCtx(() => [_createVNode(_unref(Document))]),
        _: 1
      }), _cache[2] || (_cache[2] = _createElementVNode("p", null, "列表为空", -1))])) : _createCommentVNode("", true)]), _createVNode(_unref(StoreDialog), {
        visible: storeDialogVisible.value,
        "onUpdate:visible": _cache[1] || (_cache[1] = $event => storeDialogVisible.value = $event),
        store: selectedStore.value,
        onConfirm: confirmStore
      }, null, 8, ["visible", "store"])]);
    };
  }
};
import { defineStore } from 'pinia'
import { getProductByCode } from '@/api/product'

export const useProductStore = defineStore('product', {
  state: () => ({
    product: null
  }),
  actions: {
    async fetchProductByCode(code) {
      try {
        const response = await getProductByCode({ cardNo: code })
        const { data } = response;
        data.products.forEach(product => {
          product.productDetail.rounds = product.productDetail.rounds.filter(round => round.items.length > 0);
          product.productSelectDetail.rounds = product.productSelectDetail.rounds.filter(round => round.items.length > 0);
        })
        console.log(data)
        this.product = data
      } catch (error) {
        throw error
      }
    }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "product-item-container"
};
const _hoisted_2 = {
  class: "product-info"
};
const _hoisted_3 = {
  class: "product-name"
};
const _hoisted_4 = {
  class: "product-description-container"
};
const _hoisted_5 = {
  class: "product-descriptions"
};
const _hoisted_6 = {
  class: "product-description"
};
import { Edit } from '@element-plus/icons-vue';
import { useOrderStore } from '@/stores/orderStore';
import { storeToRefs } from 'pinia';
export default {
  __name: 'index',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  emits: ['openCustomization'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const orderStore = useOrderStore();
    const {
      selectedItems
    } = storeToRefs(orderStore);
    const props = __props;
    const getSelectedItem = round => {
      const selected = selectedItems.value[round.id];
      if (selected && selected.length > 0) {
        return round.items.find(item => item.itemSkuCode === selected[0]);
      }
      const defaultItem = round.items.find(item => item.isDefault === 'Y');
      return defaultItem || round.items[0];
    };
    const openCustomization = () => {
      orderStore.setSelectedProduct(props.product);
      emit('openCustomization');
    };
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_image, {
        class: "product-image",
        src: __props.product.imageUrl,
        fit: "cover"
      }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h3", _hoisted_3, _toDisplayString(__props.product.productName), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.product.productSelectDetail?.rounds, round => {
        return _openBlock(), _createElementBlock("div", {
          key: round.id
        }, [_createElementVNode("p", _hoisted_6, [_createTextVNode(_toDisplayString(round.quantity) + " × " + _toDisplayString(getSelectedItem(round).itemSkuName) + " ", 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getSelectedItem(round).productAttributes, attr => {
          return _openBlock(), _createElementBlock("span", {
            key: attr.id,
            class: "attr-info"
          }, _toDisplayString(attr.items.find(opt => opt.isPush === 0)?.itemName || '未选择'), 1);
        }), 128))])]);
      }), 128))]), _createVNode(_component_el_button, {
        size: "small",
        icon: _unref(Edit),
        type: "success",
        round: "",
        onClick: openCustomization,
        class: "customization-button",
        color: "#fe813b",
        dark: "",
        style: {
          "color": "#fff"
        }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("选规格")])),
        _: 1
      }, 8, ["icon"])])])]);
    };
  }
};
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "order-details"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  key: 0,
  class: "content"
};
const _hoisted_4 = {
  key: 1,
  class: "error"
};
import { useRoute } from "vue-router";
import { onUnmounted, watch, ref } from 'vue';
import { getOrderDetail } from '@/api/store';
import { useMainStore } from '@/stores/mainStore';
import TopNav from '@/components/TopNav';
import OrderSummary from './order-summary.vue';
import OrderProduct from './order-product.vue';
import OrderStatus from './order-status.vue';
import OrderInfo from './order-info.vue';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const mainStore = useMainStore();
    const orderDetails = ref();
    const fetchError = ref(false);
    const code = ref(localStorage.getItem('orderCode') || '');
    let intervalId;
    watch(() => route.params.id, () => {
      mainStore.setLoading(true);
      fetchOrderDetails(route.params.id).finally(() => mainStore.setLoading(false));
      intervalId = setInterval(fetchOrderDetails, 3000);
    }, {
      immediate: true
    });
    async function fetchOrderDetails(id) {
      try {
        const {
          data
        } = await getOrderDetail({
          id,
          card_code: code.value
        });
        orderDetails.value = data.body;
        fetchError.value = false;
      } catch (error) {
        fetchError.value = true;
        console.error(error);
      }
    }
    onUnmounted(() => {
      clearInterval(intervalId);
    });
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(TopNav), {
        title: "订单详情",
        backgroundColor: "#F5EBDC"
      })]), orderDetails.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(OrderInfo, {
        "order-details": orderDetails.value
      }, null, 8, ["order-details"]), _createVNode(OrderStatus, {
        "order-details": orderDetails.value
      }, null, 8, ["order-details"]), _createVNode(OrderProduct, {
        "order-details": orderDetails.value
      }, null, 8, ["order-details"]), _createVNode(OrderSummary, {
        "order-details": orderDetails.value
      }, null, 8, ["order-details"])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_el_image, {
        src: "@/assets/images/ic-network-error.png",
        fit: "contain",
        class: "error-image"
      }), _cache[1] || (_cache[1] = _createElementVNode("p", null, "获取接口失败，请检查网络连接。", -1)), _createVNode(_component_el_button, {
        onClick: fetchOrderDetails,
        type: "primary"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("点击刷新")])),
        _: 1
      })]))]);
    };
  }
};